import {combineReducers} from 'redux-immutable'
import ui from './ui'
import auth from './auth'
import tools from './tools'

const rootReducer = combineReducers({
  ui, auth, tools,
})

export default rootReducer
