import React, {memo} from 'react'
import {Link} from 'react-router-dom'
import {
  Card, Col, Row, Tabs
} from 'antd'

import styles from './index.less'

const Dashboard = () => (
  <div className={styles.root}>
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="系统应用" key="1">
        <Row gutter={16} className={styles.items}>
          <Col xs={12} sm={6}>
            <a href="/storage/">
              <Card bordered={false}>库存管理系统</Card>
            </a>
          </Col>
          <Col xs={12} sm={6}>
            <a href="/counter/">
              <Card bordered={false}>收银台</Card>
            </a>
          </Col>
          <Col xs={12} sm={6}>
            <a href="/admin/">
              <Card bordered={false}>管理后台</Card>
            </a>
          </Col>
          <Col xs={12} sm={6}>
            <a href="/reading/">
              <Card bordered={false}>阅读工具</Card>
            </a>
          </Col>
        </Row>
      </Tabs.TabPane>
      <Tabs.TabPane tab="小工具" key="2">
        <Row gutter={16} className={styles.items}>
          <Col xs={12} sm={6}>
            <Link to="/tools/encrypt">
              <Card bordered={false}>加密工具</Card>
            </Link>
          </Col>
        </Row>
      </Tabs.TabPane>
    </Tabs>

  </div>
)

export default memo(Dashboard)
