import React, {memo, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Link, Route, Switch} from 'react-router-dom'
import {
  Breadcrumb, Icon, Layout, Menu
} from 'antd'

import {AdminRoutes} from '../../../routes'
import {AuthActions} from '../../../actions'

const {Content, Sider} = Layout

const NoMatch = () => (
  <div>
    Page NotFound
  </div>
)

const AdminLayout = () => {
  const dispatch = useDispatch()
  const handleLogout = () => dispatch(AuthActions.logout())
  useEffect(() => {
    dispatch(AuthActions.getLoginUser())
  }, [dispatch])

  return (
    <Layout>
      <Sider width={200} style={{background: '#fff'}}>
        <Menu mode="inline"
              style={{height: '100%', borderRight: 0}}
              defaultSelectedKeys={[`m:${window.location.pathname}`]}>
          <Menu.Item key="m:/admin/">
            <Link to="/admin/">Welcome</Link>
          </Menu.Item>
          <Menu.Item key="m:/account">
            <Link to="/admin/account">アカウント一覧1</Link>
          </Menu.Item>
          <Menu.Item onClick={handleLogout}>
            <Icon type="logout"/>ログアウト
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{padding: '0 24px 24px'}}>
        <Breadcrumb style={{margin: '16px 0'}}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb>
        <Content style={{
          background: '#fff',
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}>
          <Switch>
            {AdminRoutes.map(route => (
              <Route key={`r:${route.path}`} exact path={route.path} component={route.component}/>
            ))}
            <Route component={NoMatch}/>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}

export default memo(AdminLayout)
