import React from 'react'
import ReactDOM from 'react-dom'
import {
  Router, Switch, Route, Redirect
} from 'react-router-dom'
import {Provider} from 'react-redux'

import './index.css'
import * as serviceWorker from './serviceWorker'

import history from './routes/history'
import store from './store'

import Login from './pages/home/login'
import AdminLayout from './pages/admin/layout/index'
import DefaultLayout from './pages/home/layout'

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route exact path="/login" component={Login}/>
        <Switch>
          <Route path="/admin" component={AdminLayout}/>
          <Route path="/" component={DefaultLayout}/>
        </Switch>
        <Redirect to="/"/>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
