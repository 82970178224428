import React, {memo} from 'react'
import {useDispatch} from 'react-redux'

import {
  Button, Form, Input
} from 'antd'
import {UserOutlined, LockOutlined} from '@ant-design/icons'

import {AuthActions} from '../../../actions'

import styles from './index.less'

const Login = () => {
  const dispatch = useDispatch()

  const handleFinish = (values) => {
    dispatch(AuthActions.login(values))
  }

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <span className={styles.title}>
          管理者サイト
        </span>
      </div>
      <Form onFinish={handleFinish}
            className={styles.form}>
        <Form.Item name="email"
                   rules={[
                     {required: true, message: 'Please input your email address'}
                   ]}>
          <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                 placeholder="Username"
                 type="text"
                 autoComplete="off"
                 size="large"/>
        </Form.Item>
        <Form.Item name="password"
                   rules={[
                     {required: true, message: 'Please input your password'}
                   ]}>
          <Input prefix={<LockOutlined className="site-form-item-icon"/>}
                 placeholder="Password"
                 type="password"
                 autoComplete="off"
                 size="large"/>
        </Form.Item>
        <Form.Item>
          <Button type="primary"
                  htmlType="submit"
                  className={styles.submit}
                  size="large">
            ログイン
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default memo(Login)
