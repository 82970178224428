import React, {memo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  Button, Form, Input, Typography
} from 'antd'

import {ToolsActions} from '../../../../actions'

import styles from './index.less'

const {Paragraph} = Typography

const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const CryptoUtil = {
  generateRandomKey(len) {
    let text = ''
    for (let i = 0; i < len; i += 1) {
      text += possible.charAt(Math.floor(Math.random() * 62))
    }
    return text
  },
}

const ToolEncrypt = () => {
  const [form] = Form.useForm()
  const {content} = useSelector(state => ({
    content: state.getIn(['tools', 'content'])
  }), _.isEqual)
  const [key, setKey] = useState('')
  const dispatch = useDispatch()

  const handleEncrypt = () => {
    form.validateFields().then((values) => {
      dispatch(ToolsActions.encrypt(values))
    })
  }
  const handleDecrypt = () => {
    form.validateFields().then((values) => {
      dispatch(ToolsActions.decrypt(values))
    })
  }
  const handleGenerate = () => {
    setKey(CryptoUtil.generateRandomKey(12))
  }

  return (
    <div>
      <Form className={styles.form}
            layout="vertical"
            form={form}>
        <Form.Item label="内容"
                   name="content">
          <Input.TextArea rows={4} placeholder="请输入原文内容或加密内容"/>
        </Form.Item>
        <Form.Item label="密码"
                   name="key"
                   rules={[
                     {required: true, message: '密码不能为空'}
                   ]}>
          <Input type="password" placeholder="密码"/>
        </Form.Item>
        <Form.Item className={styles.buttonLine}>
          <Button type="primary" onClick={handleEncrypt}>加密</Button>
          <Button type="primary" onClick={handleDecrypt}>解密</Button>
          <Button onClick={handleGenerate}>生成密码</Button>
          {key && <Paragraph copyable className={styles.generate}>{key}</Paragraph>}
        </Form.Item>
        <Paragraph copyable
                   className={styles.content}>
          {content || '此处显示加密后内容或原文内容！'}
        </Paragraph>
      </Form>
    </div>
  )
}

export default memo(ToolEncrypt)
