import React, {memo, useEffect} from 'react'
import {Link, Route, Switch} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  Layout, Menu, Modal
} from 'antd'
import {UserOutlined} from '@ant-design/icons'

import {AuthActions} from '../../../actions'
import {HomeRoutes} from '../../../routes'

import styles from './index.less'


const NotFound = () => (
  <div>
    出错啦！！！页面没找到！！！
  </div>
)

const DefaultLayout = () => {
  const dispatch = useDispatch()
  const {isLogin, account, initial} = useSelector(state => ({
    isLogin: state.getIn(['auth', 'isLogin']),
    initial: state.getIn(['auth', 'initial']),
    account: state.getIn(['auth', 'account'])
  }), _.isEqual)

  const handleClickMenu = (e) => {
    if (e.key === 'logout') {
      Modal.confirm({
        title: '确定要登出?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          dispatch(AuthActions.logout())
        }
      })
    }
  }

  useEffect(() => {
    if (!initial) {
      dispatch(AuthActions.initialStart())
    }
  }, [dispatch, initial])

  return (
    <Layout className={styles.root}>
      <Layout.Header className={styles.header}>
        <div className={styles.navPanel}>
          <Menu mode="horizontal"
                selectable={false}>
            <Menu.Item key="1"><Link to="/">首页</Link></Menu.Item>
          </Menu>
          <div className={styles.rightPanel}>
            {isLogin ? (
              <Menu mode="horizontal"
                    selectable={false}
                    onClick={handleClickMenu}>
                <Menu.SubMenu title={<span><UserOutlined/>{account.get('name')}</span>}>
                  <Menu.Item key="changePassword"><Link to="/change_password">修改密码</Link></Menu.Item>
                  <Menu.Item key="logout">
                    登出
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            ) : (
              <Menu mode="horizontal"
                    selectable={false}>
                <Menu.Item><Link to="/login">登录</Link></Menu.Item>
              </Menu>
            )}
          </div>
        </div>
      </Layout.Header>
      <Layout.Content>
        <div className={styles.content}>
          <Switch>
            {HomeRoutes.map(route => (
              <Route key={`r:${route.path}`} exact path={route.path} component={route.component}/>
            ))}
            <Route component={NotFound}/>
          </Switch>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default memo(DefaultLayout)
