import api from './api'

export default {
  encrypt({key, content}) {
    return api.post('/api/v1/tools/security/encrypt', {key, content}, false)
  },
  decrypt({key, content}) {
    return api.post('/api/v1/tools/security/decrypt', {key, content}, false)
  },
}
