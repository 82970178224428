import api from './api'

export default {
  auth({email, password}) {
    return api.post('/api/v1/auth/token', {grantType: 1, email, password}, false)
      .then((res) => {
        api.updateToken(res)
      })
  },
  revoke() {
    const refreshToken = localStorage.getItem('rt')
    return api.post('/api/v1/auth/revoke', {refreshToken})
      .then(() => {
        api.clearToken()
      })
  },
  token() {
    const token = localStorage.getItem('rt')
    return api.post('/api/v1/auth/token', {grantType: 2, token})
  },
}
