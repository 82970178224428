import {
  takeEvery
} from 'redux-saga/effects'
import {notification, Modal} from 'antd'
import {UIConstants} from '../constants'

function showError(action) {
  const {message = '', description} = action.payload
  notification.error({
    message,
    description,
    duration: 0,
  })
}

function showSuccess(action) {
  const {message = '', description} = action.payload
  notification.success({
    message,
    description
  })
}

function showAlert(action) {
  const {title, content} = action.payload
  Modal.info({title, content})
}

export default [
  takeEvery(UIConstants.SHOW_NOTIFICATION_ERROR, showError),
  takeEvery(UIConstants.SHOW_NOTIFICATION_SUCCESS, showSuccess),
  takeEvery(UIConstants.SHOW_ALERT, showAlert)
]
