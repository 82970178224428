import {handleActions} from 'redux-actions'
import Immutable from 'immutable'
import {AuthConstants} from '../constants'

const AuthState = Immutable.fromJS({
  initial: false,
  isLogin: false,
  account: {}
})

const AuthReducer = handleActions({
  [AuthConstants.LOGIN_SUCCESS]: state => state.set('isLogin', true),
  [AuthConstants.LOGIN_FAIL]: state => state.withMutations((s) => {
    s.set('isLogin', false)
      .set('account', Immutable.Map())
  }),
  [AuthConstants.LOGOUT_SUCCESS]: state => state.withMutations((s) => {
    s.set('isLogin', false)
      .set('account', Immutable.Map())
  }),
  [AuthConstants.GET_LOGIN_USER_SUCCESS]: (state, {payload}) => state.withMutations((s) => {
    s.set('isLogin', true)
      .set('account', Immutable.fromJS(payload.account))
  }),
  [AuthConstants.INITIAL_SUCCESS]: (state, {payload}) => state.withMutations((s) => {
    s.set('initial', true)
    if (payload.account) {
      s.set('isLogin', true)
        .set('account', Immutable.fromJS(payload.account))
    }
  })
}, AuthState)

export default AuthReducer
