import {takeEvery, call, put} from 'redux-saga/effects'
import {ToolsConstants} from '../constants'
import {ToolsAPI} from '../../apis'
import {UIActions, ToolsActions} from '../../actions'

function* encrypt(action) {
  try {
    const res = yield call(ToolsAPI.encrypt, action.payload)
    yield put(ToolsActions.encryptSuccess(res))
  } catch (err) {
    yield put(UIActions.showNotificationError(err))
  }
}

function* decrypt(action) {
  try {
    const res = yield call(ToolsAPI.decrypt, action.payload)
    yield put(ToolsActions.decryptSuccess(res))
  } catch (err) {
    yield put(UIActions.showNotificationError(err))
  }
}

export default [
  takeEvery(ToolsConstants.ENCRYPT_START, encrypt),
  takeEvery(ToolsConstants.DECRYPT_START, decrypt),
]
