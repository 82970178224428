import {handleActions} from 'redux-actions'
import Immutable from 'immutable'
import {ToolsConstants} from '../constants'

const ToolsState = Immutable.fromJS({
  content: '',
})

const ToolsReducer = handleActions({
  [ToolsConstants.ENCRYPT_SUCCESS]: (state, {payload}) => state.set('content', payload.content),
  [ToolsConstants.DECRYPT_SUCCESS]: (state, {payload}) => state.set('content', payload.content),
}, ToolsState)

export default ToolsReducer
