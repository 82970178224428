const RefreshTokenKey = 'rt'
const AccessTokenKey = 'at'
const ExpireInTimeKey = 'eit'
const expireInTimeOffset = 120

let accessToken = localStorage.getItem(AccessTokenKey) || ''
let refreshToken = localStorage.getItem(RefreshTokenKey) || ''
let expireInTime = parseInt(localStorage.getItem(ExpireInTimeKey), 10) || 0

function parseJSON(response) {
  return response.json()
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  return response.json().then(res => Promise.reject(res))
}

const api = {
  get(url, body, needToken = true) {
    if (body) {
      url += (url.indexOf('?') === -1 ? '?' : '&') + Object.keys(body)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(body[k])}`)
        .join('&')
    }
    return this.request(url, {}, needToken)
  },
  post(url, body, needToken = true) {
    return this.request(url, {body: JSON.stringify(body), method: 'POST'}, needToken)
  },
  put(url, body, needToken = true) {
    return this.request(url, {body: JSON.stringify(body), method: 'PUT'}, needToken)
  },
  del(url, needToken = true) {
    return this.request(url, {method: 'DELETE'}, needToken)
  },
  request(url, options, needToken = true) {
    // eslint-disable-next-line no-use-before-define
    return prepareToken(options, needToken).then(() => fetch(url, options)
      .then(checkStatus)
      .then(parseJSON)
    )
  },
  updateToken(tokenInfo) {
    accessToken = tokenInfo.accessToken
    refreshToken = tokenInfo.refreshToken
    expireInTime = new Date().getTime() + (Number(tokenInfo.expiresIn) - expireInTimeOffset) * 1000
    localStorage.setItem(AccessTokenKey, accessToken)
    localStorage.setItem(RefreshTokenKey, refreshToken)
    localStorage.setItem(ExpireInTimeKey, expireInTime)
  },
  clearToken() {
    accessToken = ''
    refreshToken = ''
    expireInTime = 0
    localStorage.removeItem(AccessTokenKey)
    localStorage.removeItem(RefreshTokenKey)
    localStorage.removeItem(ExpireInTimeKey)
  }
}

let refreshTokenTask

function prepareToken(options, needToken = true) {
  if (!needToken) {
    return Promise.resolve()
  }

  if (!accessToken || expireInTime < new Date().getTime()) {
    if (!refreshToken) {
      return Promise.reject(new Error('Login session timeout.'))
    }
    if (refreshTokenTask) {
      return refreshTokenTask
    }
    refreshTokenTask = api.post('/api/v1/auth/token', {grantType: 2, token: refreshToken}, false)
      .then((res) => {
        api.updateToken(res)
        refreshTokenTask = null

        options.headers = options.headers || {}
        options.headers.Authorization = `${accessToken}`
      })

    return refreshTokenTask
  }

  // use local
  options.headers = options.headers || {}
  options.headers.Authorization = `bearer ${accessToken}`

  return Promise.resolve()
}

export default api
