import api from './api'

export default {
  getUser() {
    return api.get('/api/v1/user/me').catch((err) => {
      api.clearToken()
      return Promise.reject(err)
    })
  }
}
