import {AuthConstants} from '../store/constants'

export default {
  login({email, password}) {
    return {type: AuthConstants.LOGIN_START, payload: {email, password}}
  },
  loginSuccess() {
    return {type: AuthConstants.LOGIN_SUCCESS}
  },
  loginFail() {
    return {type: AuthConstants.LOGIN_FAIL}
  },
  logout() {
    return {type: AuthConstants.LOGOUT_START}
  },
  logoutSuccess() {
    return {type: AuthConstants.LOGOUT_SUCCESS}
  },
  getLoginUser() {
    return {type: AuthConstants.GET_LOGIN_USER_START}
  },
  getLoginUserSuccess(account) {
    return {type: AuthConstants.GET_LOGIN_USER_SUCCESS, payload: {account}}
  },
  initialStart() {
    return {type: AuthConstants.INITIAL_START}
  },
  initialSuccess(account) {
    return {type: AuthConstants.INITIAL_SUCCESS, payload: {account}}
  }
}
