import {all} from 'redux-saga/effects'

import auth from './auth'
import ui from './ui'
import tools from './tools'

export default function* () {
  yield all([
    ...auth,
    ...ui,
    ...tools,
  ])
}
