import React, {memo} from 'react'
import {useDispatch} from 'react-redux'
import {Button} from 'antd'

import {AuthActions} from '../../../actions'

const Welcome = () => {
  const dispatch = useDispatch()
  const handleLogout = () => dispatch(AuthActions.logout())
  return (
    <div>
      Welcome!!!
      <Button onClick={handleLogout}>Logout</Button>
    </div>
  )
}

export default memo(Welcome)
