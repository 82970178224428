import {
  put, takeLatest, takeLeading, all, call, takeEvery
} from 'redux-saga/effects'

import history from '../../routes/history'
import {AuthActions, UIActions} from '../../actions'
import {AuthAPI, AccountAPI} from '../../apis'

import {AuthConstants} from '../constants'

function* login(action) {
  const {email, password} = action.payload
  try {
    yield call(AuthAPI.auth, {email, password})

    yield all([
      put(AuthActions.loginSuccess()),
      put(AuthActions.getLoginUser()),
      put(UIActions.showNotificationSuccess({message: 'Login success'}))
    ])
    history.push('/')
  } catch (err) {
    yield all([
      put(AuthActions.loginFail()),
      put(UIActions.showNotificationError(err, {message: 'Login failed', description: err.message}))
    ])
  }
}

function* logout() {
  try {
    yield call(AuthAPI.revoke)
  } catch (err) {
    // todo ログ出力機能
  }
  yield all([
    put(AuthActions.logoutSuccess()),
    put(UIActions.showNotificationSuccess({message: 'Logout success'}))
  ])
  history.push('/login')
}

function* getLoginUser() {
  try {
    const {user} = yield call(AccountAPI.getUser)
    yield put(AuthActions.getLoginUserSuccess(user))
  } catch (err) {
    yield put(UIActions.showNotificationError(err))
    history.push('/login')
  }
}

function* initial() {
  try {
    const {user} = yield call(AccountAPI.getUser)
    yield put(AuthActions.initialSuccess(user))
  } catch (err) {
    yield put(AuthActions.initialSuccess())
  }
}

export default [
  takeLatest(AuthConstants.LOGIN_START, login),
  takeLatest(AuthConstants.LOGOUT_START, logout),
  takeLeading(AuthConstants.GET_LOGIN_USER_START, getLoginUser),
  takeEvery(AuthConstants.INITIAL_START, initial)
]
