export const AuthConstants = {
  LOGIN_START: 'AUTH/LOGIN_START',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_FAIL: 'AUTH/LOGIN_FAIL',

  LOGOUT_START: 'AUTH/LOGOUT_START',
  LOGOUT_SUCCESS: 'AUTH/LOGOUT_SUCCESS',

  GET_LOGIN_USER_START: 'AUTH/GET_LOGIN_USER_START',
  GET_LOGIN_USER_SUCCESS: 'AUTH/GET_LOGIN_USER_SUCCESS',

  INITIAL_START: 'AUTH/INITIAL_START',
  INITIAL_SUCCESS: 'AUTH/INITIAL_SUCCESS',
}

export const UIConstants = {
  SHOW_LOADING: 'UI/SHOW_LOADING',
  HIDE_LOADING: 'UI/HIDE_LOADING',
  SHOW_NOTIFICATION_ERROR: 'UI/SHOW_NOTIFICATION_ERROR',
  SHOW_NOTIFICATION_SUCCESS: 'UI/SHOW_NOTIFICATION_SUCCESS',

  SHOW_ALERT: 'UI/SHOW_ALERT'
}

export const ToolsConstants = {
  ENCRYPT_START: 'TOOLS/ENCRYPT_START',
  ENCRYPT_SUCCESS: 'TOOLS/ENCRYPT_SUCCESS',

  DECRYPT_START: 'TOOLS/DECRYPT_START',
  DECRYPT_SUCCESS: 'TOOLS/DECRYPT_SUCCESS'
}
