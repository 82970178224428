import {handleActions} from 'redux-actions'
import Immutable from 'immutable'
import {UIConstants} from '../constants'

const UIState = Immutable.fromJS({
  loading: false
})

const UIReducer = handleActions({
  [UIConstants.SHOW_LOADING]: state => state.set('loading', true),
  [UIConstants.HIDE_LOADING]: state => state.set('loading', true)
}, UIState)

export default UIReducer
