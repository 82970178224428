import {ToolsConstants} from '../store/constants'

export default {
  encrypt({key, content}) {
    return {type: ToolsConstants.ENCRYPT_START, payload: {key, content}}
  },
  encryptSuccess({content}) {
    return {type: ToolsConstants.ENCRYPT_SUCCESS, payload: {content}}
  },
  decrypt({key, content}) {
    return {type: ToolsConstants.DECRYPT_START, payload: {key, content}}
  },
  decryptSuccess({content}) {
    return {type: ToolsConstants.DECRYPT_SUCCESS, payload: {content}}
  },
}
