import {UIConstants} from '../store/constants'

export default {
  showNotificationError(err, options) {
    const content = (options && options.description) || (err && err.message) || ''
    return {
      type: UIConstants.SHOW_NOTIFICATION_ERROR,
      payload: {
        message: (options && options.message) || 'Error',
        description: content
      }
    }
  },
  showNotificationSuccess({message = '', description}) {
    return {type: UIConstants.SHOW_NOTIFICATION_SUCCESS, payload: {message, description}}
  },
  showAlert({title, content}) {
    return {type: UIConstants.SHOW_ALERT, payload: {title, content}}
  }
}
