import {createStore, applyMiddleware} from 'redux'
import createSagaMiddleware from 'redux-saga'
import Immutable from 'immutable'

import rootReducer from './reducers'
import rootSaga from './sagas'

const initialState = Immutable.Map()
const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line
  const {createLogger} = require('redux-logger')
  middleware.push(createLogger({collapsed: true, stateTransformer: state => state.toJS()}))
}

const store = createStore(rootReducer, initialState, applyMiddleware(...middleware))

sagaMiddleware.run(rootSaga)

export default store
